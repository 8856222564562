
import { Component, Mixins } from 'vue-property-decorator';
import ConfigurationStatus from '@/components/data-operations/common/configuration/ConfigurationStatus.vue';

import HeaderInfosMixin from '../header-infos';

import { TABLES_TO_TABLES_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIVATED,
	CONF_CLIENT_VERSION,
	CONF_WORKFLOW_LENGTH,
	DEFAULT_BQ_DATASET,
	ENVIRONMENT,
	ID,
} from '@/constants/data-operations/listing/header-items';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';
import { getGbqToGbqConfs } from '@/store/modules/easy-firestore/get-gbq-to-gbq-confs';

@Component({
	components: { ConfigurationStatus },
})
export default class TablesToTablesConfigurationsListingView extends Mixins(
	HeaderInfosMixin,
	ConfigurationCollectionMixin
) {
	moduleName: string = getGbqToGbqConfs.moduleName;
	overriddenColumns: string[] = ['id'];

	get routeName() {
		return TABLES_TO_TABLES_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ENVIRONMENT, CONF_CLIENT_VERSION, ID, DEFAULT_BQ_DATASET, CONF_WORKFLOW_LENGTH, ACTIVATED];
	}
}
